body {
	// margin         	: var(--header-height) 0 0 0;
	min-height     	: calc(100vh - var(--header-height));
	background 		: var(--body-bg);
	display        	: flex;
	flex-direction 	: column;
	box-sizing: border-box;
}

.main-header{
	
}

.main-footer {

}
