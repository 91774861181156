.login {
	height: 100vh;
	color: var(--color-first);
	position: relative;

	
	&__container {
		max-width: 1280px;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	
	&__container-center {
		max-width: 355px;
		width: 300px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 0 8px;

		@include from(l) {
			width: 100%;
			padding: 0;
		}
	}

	&__form {
		background-color: var(--color-background);
		display: flex;
		flex-direction: column;
		padding: 2rem;
		border-radius: 1rem;
		border: 1px solid var(--color-first);
		align-items: center;
	}

	&__form--img {
		margin-bottom: 24px;
		height: 58px;
		width: 219px;
	}
	
	&__form--label {
		width: 100%;
		margin-bottom: 5px;
	}
	
	&__form--input {
		width: 100%;
		border: 1px solid var(--color-first);
		border-radius: 5px;
		padding: 0.6rem;
	}

	&__btns-login {
		display: flex;
		flex-direction: column;
		gap: 13px;
		margin-top: 40px;
		width: 100%;
	}

	&__btns-rules {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		gap: 13px;
		
		a {
			width: 50%;
		}
	}
	
	&__btns--languages {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		justify-content: center;
		margin-top: 13px;
	}

	&__btns--a{
		border: none;
		background-color: transparent;
		cursor: pointer;
	}
}

.with-background {
	background-image: url(../../assets/img/main-image-mob.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	
	@include from(m) {
		background-image: url(../../assets/img/main-image-desktop.jpg);
	}
}
