//layout
:root {
  --header-height 		: 3rem;
  @include from(l) {
    --header-height 	: 4rem;
  }
	//native color vars
	--color-first      			: #FF6600;
	--color-first-dark      : #E44B27;
	--color-second      		: #003366;
	--color-third						: #003c66;
	--color-container      	: #230119;
	--color-background      : #FFFFFF;
	--color-button-hover		: #ff9900;
}
// Sass Color Vars
$color-first 			: var(--color-first);


*,
*::before,
*::after {
	box-sizing: border-box;
}

::selection {
	color: white;
	background: var(--color-first );
}

strong {
	font-weight 	: 700;
}
