.sport-rules {
	&__table {
		color: var(--color-first);
	}
	
	&__table{
		border-collapse: separate;
		border-spacing: 0 5px;
		width: 970px;
		margin-left: auto;
		margin-right: auto;
	}
	
	&__table--col1 {
		border-right: 0.96px solid var(--color-first);
	}
	
	&__table--th {
		font-weight: 500;
		border-bottom: 0.96px solid var(--color-first);
		padding: 4px 1rem;
		width: 220px;
	}
	
	&__table--th-bold {
		border-bottom: 0.96px solid var(--color-first);
		font-weight: 800;
		text-align: center;
	}
	
	&__table--td1 {
		background-color: var(--color-first);
		color: var(--color-background);
		padding: 0.5rem 1rem;
		border: 0.96px solid var(--color-first);
		width: 220px;
		text-align: center;
		text-transform: uppercase;
	}
	
	&__table--td2 {
		padding: 0.5rem 1rem;
		border: 0.96px solid var(--color-first);
		color: var(--color-background);
		font-weight: 300;
	}

	&__container-div {
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
	}
	
	&__ul {
		margin: 0;
		padding: 0;
		list-style: none;
		margin-bottom: 0.5rem;

		@include from(l) {
			display: flex;
			flex-direction: row;
		}
	}

	&__div--li1 {
		background-color: var(--color-first);
		color: var(--color-background);
		padding: 0.5rem 1rem;
		border: 0.96px solid var(--color-first);
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		
		@include from(l) {
			width: 20%;
		}
	}
	
	&__div--li2 {
		padding: 1rem 1rem;
		border: 0.96px solid var(--color-first);
		color: var(--color-background);
		font-weight: 300;
		width: 100%;
		text-align: center;
		
		@include from(l) {
			width: 80%;
			text-align: left;
			padding: 0.5rem 1rem;
		}
	}
	
}


.display {
	display: none;

	@include from(l) {
		display: block;
	}
}


.container__slider {
	@include from(l) {
		display: none;
	}
}
